import util from '@bmp-vue/core/api/util'

const controller = 'ParceiroHeaders'
export default Object.assign({}, util.GetCrud(controller, null), {

    GetParceiroCallbackHeaderPorId: function (codigoParceiro) {
        const url = `GetParceiroCallbackHeaderPorId?codigoParceiro=${codigoParceiro}`
        return util.Axios.GetAll(`${controller}/${url}`)
    },   

    GetListaParceiroCallbackHeaderPorId: function (codigoParceiro) {        
        const url = `GetListaParceiroCallbackHeaderPorId?codigoParceiro=${codigoParceiro}`
        return util.Axios.GetAll(`${controller}/${url}`)
    }, 
})
