import util from '@bmp-vue/core/api/util'

const controller = 'Cobranca'
export default Object.assign({}, util.GetCrud(controller, ['getall']), {
    GetQuantidadeCobrancas(dto) {
        return util.Axios.GetAll(controller + '/Quantidade', dto)
    },
    GetCobrancasDetalhes: function (dto) {
        return util.Axios.GetAll(controller + '/Detalhes', dto)
    },
    GetCobrancasGeral: function (dto) {
        return util.Axios.GetAll(controller + '/Geral', dto)
    }
})

