// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import core from '@bmp-vue/core'
import { datadogRum } from '@datadog/browser-rum';
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)
const localApp = new Vue({})

core.carregaEmpresa = false
core.carregaMenuDinamico = false

const controllers = require.context(
  '@/controllers', true, /\.js$/
)
core.methods.init(controllers)

function isApiLoaded() {
  setTimeout(function () {
    if (core.store.getters.loading || core.store.getters.activeRequests > 0) {
      isApiLoaded()
      return false
    }

    const paths = require('@/plugins/router/paths').default
    const theme = require('@/plugins/vuetify/theme').default
    const App = () => import('./App')

    core.api.UI.ShowError = (title, message) => notify(title || 'Erro', message, 'error')
    core.api.UI.ShowAlert = (title, message) => notify(title || 'Atenção', message, 'warn')
    core.api.UI.ShowSuccess = (title, message) => notify(title || 'Sucesso', message, 'success')

    core.methods.finish(paths, theme, App)
  }, 100)
}

isApiLoaded()

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: '12cfae64-9ecc-45b6-b8b4-c58d4b092d54',
    clientToken: 'pub6779ab82f47cb86d7d85049a22008cb2',
    site: 'datadoghq.com',
    service: 'safe-web',
    env: 'production',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
}

function notify (title, message, type) {
  if (message && message.message) { message = message.message }
  localApp.$notify({
    group: 'notifications-bottom-right',
    type: type,
    title: title || 'Erro',
    text: message ? message.replace(/\n/g, '<br/>') : '',
    duration: 7000
  })
}
