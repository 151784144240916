import util from '@bmp-vue/core/api/util'

const controller = 'Arrc018';
export default Object.assign({}, util.GetCrud(controller, ['getall']), {
    GetDadosExcelGeral: function (dto) {
        return util.Axios.GetAll(controller + '/GetDadosExcel/Geral', dto)
    },
    GetDadosExcelDetalhes: function (dto) {
        return util.Axios.GetAll(controller + '/GetDadosExcel/Detalhes', dto)
    },
    GetArrc018: function (dto) {
        return util.Axios.GetAll(controller + '/GetArrc018', dto)
    },
    GetBcarqByCodigoArrc018: function (dto) {
        return util.Axios.GetAll(controller + '/GetBcarqByCodigoArrc018', dto)
    },
    GetSisarqByCodigoArrc018: function (dto) {
        return util.Axios.GetAll(controller + '/GetSisarqByCodigoArrc018', dto)
    },
    GetUnidadeRecebivelByCodigoUsuarioFinalRecebedor: function (dto) {
        return util.Axios.GetAll(controller + '/GetUnidadeRecebivelByCodigoUsuarioFinalRecebedor', dto)
    },
    GetDomicilioBancarioInstituicaoByCodigoTitular: function (dto) {
        return util.Axios.GetAll(controller + '/GetDomicilioBancarioInstituicaoByCodigoTitular', dto)
    },
    GetNegociacaoRecebivelOutrasInstituicoes: function (dto) {
        return util.Axios.GetAll(controller + '/GetNegociacaoRecebivelOutrasInstituicoes', dto)
    },
    GetAllArquivos: function (dto) {
        return util.Axios.GetAll(controller + "/Arquivo", dto)
    }
});  
