/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */

import api from "@bmp-vue/core/api";
const TipoPerfilOperador = api.Enums.TipoPerfilOperador;

export default [
  {
    title: "Dashboard",
    icon: "fas fa-home",
    path: "/dashboard",
    view: "Dashboard",
    dir: "views/app/dashboard/",
    menu: true,
    meta: {
      roles: [TipoPerfilOperador.Nenhum],
      allRolesRequired: false
    }
  },
  {
    title: "Admin",
    icon: "fas fa-user-cog",
    path: "/admin/",
    dir: "views/app/admin/",
    menu: true,
    meta: {
      roles: [
        TipoPerfilOperador.Administrador,
        TipoPerfilOperador.Supervisor,
        TipoPerfilOperador.Operador,
        TipoPerfilOperador.Atendimento
      ],
      allRolesRequired: false
    },
    items: [
      {
        path: "pessoa",
        view: "Pessoa",
        title: "Estabelecimento Comercial",
        menu: true,
        meta: {
          roles: [
            TipoPerfilOperador.Administrador,
            TipoPerfilOperador.Supervisor,
            TipoPerfilOperador.Operador,
            TipoPerfilOperador.Atendimento
          ],
          allRolesRequired: false
        }
      },
      {
        path: "credenciadoraDBS",
        view: "CredenciadoraDBS",
        title: "Credenciadoras Arquivaria DBS",
        menu: true,
        meta: {
          roles: [
            TipoPerfilOperador.Administrador,
            TipoPerfilOperador.Supervisor
          ],
          allRolesRequired: false
        }
      },
      {
        path: "antecipacaoRamificacao",
        view: "AntecipacaoRamificacao",
        title: "Antecipação Ramificação",
        menu: true,
        meta: {
          roles: [
            TipoPerfilOperador.Administrador,
            TipoPerfilOperador.Supervisor
          ],
          allRolesRequired: false
        }
      },
      {
        path: "banco",
        view: "Banco",
        title: "Banco",
        menu: true,
        meta: {
          roles: [
            TipoPerfilOperador.Administrador
          ],
          allRolesRequired: true
        }
      },
      {
        path: "parceiro",
        view: "Parceiro",
        title: "Parceiros",
        menu: true,
        meta: {
          roles: [TipoPerfilOperador.Administrador],
          allRolesRequired: true
        }
      },
      {
        path: "fundo",
        view: "Fundo",
        title: "Fundos",
        menu: true,
        meta: {
          roles: [
            TipoPerfilOperador.Administrador
          ],
          allRolesRequired: true
        }
      },
      {
        path: "empresa",
        view: "Empresa",
        title: "Empresa",
        menu: true,
        meta: {
          roles: [TipoPerfilOperador.Administrador],
          allRolesRequired: true
        }
      },
      {
        path: "operador",
        view: "Operador",
        title: "Operador",
        menu: true,
        meta: {
          roles: [TipoPerfilOperador.Administrador],
          allRolesRequired: true
        }
      },
      {
        path: "emailResponsavel",
        view: "EmailResponsavel",
        title: "Emails Responsáveis",
        menu: true,
        meta: {
          roles: [
            TipoPerfilOperador.Administrador
          ],
          allRolesRequired: true
        }
      },
      {
        path: "dominio",
        view: "Dominio",
        title: "Domínio",
        menu: true,
        meta: {
          roles: [TipoPerfilOperador.Administrador],
          allRolesRequired: true
        }
      },
      {
        path: "listaDeExclusao",
        view: "ListaDeExclusao",
        title: "Lista De Exclusão",
        menu: true,
        meta: {
          roles: [
            TipoPerfilOperador.Administrador,
            TipoPerfilOperador.Supervisor,
            TipoPerfilOperador.Operador
          ],
          allRolesRequired: false
        }
      },
      {
        path: "listaDePEAC",
        view: "ListaDePEAC",
        title: "Lista PEAC 100%",
        menu: true,
        meta: {
          roles: [
            TipoPerfilOperador.Administrador,
            TipoPerfilOperador.Supervisor,
            TipoPerfilOperador.Operador
          ],
          allRolesRequired: false
        }
      },
      {
        path: "arranjoPagamentos",
        view: "ArranjoPagamentos",
        title: "Arranjo Pagamento",
        menu: true,
        meta: {
          roles: [TipoPerfilOperador.Administrador],
          allRolesRequired: true
        }
      },
      {
        title: "Segurança",
        icon: "fas fa-lock",
        path: "seguranca/",
        dir: "seguranca/",
        menu: true,
        meta: {
          roles: [TipoPerfilOperador.Administrador],
          allRolesRequired: true
        },
        items: [
          {
            path: "audittrail",
            view: "AuditTrail",
            title: "Audit Trail",
            menu: true,
            meta: {
              roles: [TipoPerfilOperador.Administrador],
              allRolesRequired: true
            }
          },
          {
            path: "apiresource",
            view: "ApiResource",
            title: "Recursos",
            menu: true,
            meta: {
              roles: [TipoPerfilOperador.Administrador],
              allRolesRequired: true
            }
          },
          {
            path: "clients",
            view: "Client",
            title: "Clientes API",
            menu: true,
            meta: {
              roles: [TipoPerfilOperador.Administrador],
              allRolesRequired: true
            }
          }
        ]
      }
    ]
  },
  {
    title: "Monitoramento",
    icon: "fas fa-user-cog",
    path: "/monitoramento/",
    dir: "views/app/monitoramento/",
    menu: true,
    meta: {
      roles: [
        TipoPerfilOperador.Administrador
      ],
      allRolesRequired: true
    },
    items: [
      {
        path: "dashboardhealthcheck",
        view: "DashboardHealthcheck",
        title: "Healthcheck",
        menu: true,
        meta: {
          roles: [
            TipoPerfilOperador.Administrador
          ],
          allRolesRequired: true
        }
      }
    ]
  },
  {
    title: "SLC",
    icon: "fas fa-download",
    path: "/slc/",
    dir: "views/app/slc/",
    menu: true,
    meta: {
      roles: [
        TipoPerfilOperador.Administrador,
        TipoPerfilOperador.Supervisor
      ],
      allRolesRequired: false
    },
    items: [
      {
        title: "Tarefas",
        icon: "fas fa-tools",
        path: "tarefas/",
        dir: "tarefas/",
        menu: true,
        meta: {
          roles: [
            TipoPerfilOperador.Administrador,
            TipoPerfilOperador.Supervisor
          ],
          allRolesRequired: false
        },
        items: [
          {
            path: "envioSaldo",
            view: "EnvioSaldo",
            title: "Envio Saldo",
            menu: true,
            meta: {
              roles: [
                TipoPerfilOperador.Administrador,
                TipoPerfilOperador.Supervisor
              ],
              allRolesRequired: false
            }
          },
          {
            path: "envioSaldoContaMae",
            view: "EnvioSaldoContaMae",
            title: "Informe Saldo",
            menu: true,
            meta: {
              roles: [
                TipoPerfilOperador.Administrador,
                TipoPerfilOperador.Supervisor
              ],
              allRolesRequired: false
            }
          },
          {
            path: "devolucao",
            view: "Devolucao",
            title: "Devolução",
            menu: true,
            meta: {
              roles: [
                TipoPerfilOperador.Administrador,
                TipoPerfilOperador.Supervisor
              ],
              allRolesRequired: false
            }
          },
        ]
      },
      {
        title: "Processamento",
        icon: "fas fa-tools",
        path: "processamento/",
        dir: "processamento/",
        menu: true,
        meta: {
          roles: [
            TipoPerfilOperador.Administrador
          ],
          allRolesRequired: true
        },
        items: [
          {
            path: "processo",
            view: "Processo",
            title: "Processar",
            menu: true,
            meta: {
              roles: [
                TipoPerfilOperador.Administrador
              ],
              allRolesRequired: true
            }
          }
        ]
      },
      {
        title: "Consultas",
        icon: "fas fa-search",
        path: "consultas/",
        dir: "consultas/",
        menu: true,
        meta: {
          roles: [
            TipoPerfilOperador.Administrador,
            TipoPerfilOperador.Supervisor
          ],
          allRolesRequired: false
        },
        items: [
          {
            path: "arquivoRecebido",
            view: "ArquivoRecebido",
            title: "Arquivo Recebido",
            menu: true,
            meta: {
              roles: [
                TipoPerfilOperador.Administrador,
                TipoPerfilOperador.Supervisor
              ],
              allRolesRequired: false
            }
          },
          {
            path: "arquivoEnviado",
            view: "ArquivoGerado",
            title: "Arquivo Enviado",
            menu: true,
            meta: {
              roles: [
                TipoPerfilOperador.Administrador
              ],
              allRolesRequired: true
            }
          },
          {
            path: "arquivoRetorno",
            view: "ArquivoRetorno",
            title: "Arquivo Retorno",
            menu: true,
            meta: {
              roles: [
                TipoPerfilOperador.Administrador
              ],
              allRolesRequired: true
            }
          },
          {
            path: "arquivoRecebidoErro",
            view: "ArquivoRecebidoErro",
            title: "Arquivo de Erro",
            menu: true,
            meta: {
              roles: [
                TipoPerfilOperador.Administrador
              ],
              allRolesRequired: true
            }
          }
        ]
      },
      {
        title: "Configurações",
        icon: "fas fa-cog",
        path: "configuracoes/",
        dir: "configuracoes/",
        menu: true,
        meta: {
          roles: [TipoPerfilOperador.Administrador],
          allRolesRequired: true
        },
        items: [
          {
            path: "controleArquivaria",
            view: "ControleArquivaria",
            title: "Controles",
            menu: true,
            meta: {
              roles: [TipoPerfilOperador.Administrador],
              allRolesRequired: true
            }
          },
          {
            path: "gradeHoraria",
            view: "GradeHorario",
            title: "Grade de Horário",
            menu: true,
            meta: {
              roles: [TipoPerfilOperador.Administrador],
              allRolesRequired: true
            }
          }
        ]
      },
      {
        title: "Relatórios",
        icon: "fas fa-file-alt",
        path: "relatorios/",
        dir: "relatorios/",
        menu: true,
        meta: {
          roles: [
            TipoPerfilOperador.Administrador,
            TipoPerfilOperador.Supervisor
          ],
          allRolesRequired: false
        },
        items: [
          {
            path: "relatoriocreditos",
            view: "RelatorioCreditos",
            title: "Relatório de Créditos",
            menu: true,
            meta: {
              roles: [
                TipoPerfilOperador.Administrador,
                TipoPerfilOperador.Supervisor
              ],
              allRolesRequired: false
            }
          },
          {
            path: "relatoriodecreditoporperiodo",
            view: "RelatorioCreditosPorPeriodo",
            title: "Rel Cred Por Período",
            menu: true,
            meta: {
              roles: [
                TipoPerfilOperador.Administrador
              ],
              allRolesRequired: true
            }
          },
          {
            path: "naoramificados",
            view: "NaoRamificados",
            title: "Não Ramificados",
            menu: true,
            meta: {
              roles: [
                TipoPerfilOperador.Administrador
              ],
              allRolesRequired: true
            }
          },
          {
            path: "ocorrenciadez",
            view: "OcorrenciaDez",
            title: "Ocorrência 10",
            menu: true,
            meta: {
              roles: [
                TipoPerfilOperador.Administrador,
                TipoPerfilOperador.Supervisor
              ],
              allRolesRequired: false
            }
          },
          {
            path: "enviorecepcao",
            view: "EnvioRecepcao",
            title: "Envio/Recepção",
            menu: true,
            meta: {
              roles: [TipoPerfilOperador.Administrador],
              allRolesRequired: true
            }
          },
          {
            path: "divergenciadecontas",
            view: "DivergenciaDeContas",
            title: "Divergência de Contas",
            menu: true,
            meta: {
              roles: [
                TipoPerfilOperador.Administrador,
                TipoPerfilOperador.Supervisor
              ],
              allRolesRequired: false
            }
          },
          {
            path: "erroTransFundo",
            view: "ErroTransFundo",
            title: "Erro Transf. Fundo",
            menu: true,
            meta: {
              roles: [
                TipoPerfilOperador.Administrador,
                TipoPerfilOperador.Supervisor
              ],
              allRolesRequired: false
            }
          },
          {
            path: "ramificacoes",
            view: "Ramificacoes",
            title: "Ramificações",
            menu: true,
            meta: {
              roles: [
                TipoPerfilOperador.Administrador,
                TipoPerfilOperador.Supervisor
              ],
              allRolesRequired: false
            }
          },
          {
            path: "arquivaria",
            view: "Arquivaria",
            title: "Arquivaria",
            menu: true,
            meta: {
              roles: [
                TipoPerfilOperador.Administrador,
                TipoPerfilOperador.Supervisor
              ],
              allRolesRequired: false
            }
          },
          {
            path: "ramificacao",
            view: "Ramificacao",
            title: "Ramificação (novo)",
            menu: true,
            meta: {     
              roles: [
                TipoPerfilOperador.Administrador,
                TipoPerfilOperador.Supervisor
              ],
              allRolesRequired: false
            }
          },
          {
            path: "situacaoPVs",
            view: "SituacaoPVs",
            title: "Situação PVs (novo)",
            menu: true,
            meta: {     
              roles: [
                TipoPerfilOperador.Administrador,
                TipoPerfilOperador.Supervisor
              ],
              allRolesRequired: false
            }
          },
          {
            path: "teds",
            view: "Teds",
            title: "TEDs (novo)",
            menu: true,
            meta: {     
              roles: [
                TipoPerfilOperador.Administrador,
                TipoPerfilOperador.Supervisor
              ],
              allRolesRequired: false
            }
          },
          {
            path: "cobrancas",
            view: "Cobrancas",
            title: "Cobranças (novo)",
            menu: true,
            meta: {     
              roles: [
                TipoPerfilOperador.Administrador,
                TipoPerfilOperador.Supervisor
              ],
              allRolesRequired: false
            }
          },
          {
            path: "transferenciasFundo",
            view: "TransferenciasFundo",
            title: "Transferências Fundo (novo)",
            menu: true,
            meta: {     
              roles: [
                TipoPerfilOperador.Administrador,
                TipoPerfilOperador.Supervisor
              ],
              allRolesRequired: false
            }
          },
          {
            path: "nuLiquids",
            view: "NuLiquids",
            title: "NuLiquids (novo)",
            menu: true,
            meta: {     
              roles: [
                TipoPerfilOperador.Administrador,
                TipoPerfilOperador.Supervisor
              ],
              allRolesRequired: false
            }
          }
        ]
      }
    ]
  },
  {
    title: "Relatórios",
    icon: "fas fa-file-alt",
    path: "/relatorio/",
    dir: "views/app/relatorios/",
    menu: true,
    meta: {
      roles: [
        TipoPerfilOperador.Administrador,
        TipoPerfilOperador.Supervisor,
        TipoPerfilOperador.Operador
      ],
      allRolesRequired: false
    },
    items: [
      {
        path: "agendarecebivel",
        view: "AgendaArrc018",
        title: "Agenda ARRC018",
        menu: true,
        meta: {
          roles: [
            TipoPerfilOperador.Administrador,
            TipoPerfilOperador.Supervisor,
            TipoPerfilOperador.Operador
          ],
          allRolesRequired: false
        }
      },
      {
        path: "arquivoarrc018",
        view: "ArquivoArrc018",
        title: "Arquivo ARRC018",
        menu: true,
        meta: {
          roles: [
            TipoPerfilOperador.Administrador,
            TipoPerfilOperador.Supervisor,
            TipoPerfilOperador.Operador
          ],
          allRolesRequired: false
        }
      }
    ]
  },
  {
    path: "/",
    view: "Login",
    dir: "views/",
    title: "Login",
    menu: false,
    meta: {
      anonymousAuth: true,
      roles: TipoPerfilOperador.Nenhum,
      allRolesRequired: true
    }
  },
  {
    path: "/lembrar",
    view: "Lembrar",
    dir: "views/",
    title: "Lembrar",
    menu: false,
    meta: {
      anonymousAuth: true,
      roles: TipoPerfilOperador.Nenhum,
      allRolesRequired: true
    }
  },
  {
    path: "/recuperar",
    view: "Recuperar",
    dir: "views/",
    title: "Recuperar",
    // params: true,
    menu: false,
    meta: {
      anonymousAuth: true,
      roles: TipoPerfilOperador.Nenhum,
      allRolesRequired: true
    }
  }
];
