import util from '@bmp-vue/core/api/util'

const controller = 'Ted'
export default Object.assign({}, util.GetCrud(controller, []), {
    GetQuantidade(dto) {
        return util.Axios.GetAll(controller + '/Quantidade', dto)
    },
    GetEnviadas: function (dto) {
        return util.Axios.GetAll(controller + '/Enviadas', dto)
    },
    GetEnviadasDetalhes: function (dto) {
        return util.Axios.GetAll(controller + '/Enviadas/Detalhes', dto)
    },
    GetEnviadasGeral: function (dto) {
        return util.Axios.GetAll(controller + '/Enviadas/Geral', dto)
    },
    GetPendentes: function (dto) {
        return util.Axios.GetAll(controller + '/Pendentes', dto)
    },
    GetPendentesDetalhes: function (dto) {
        return util.Axios.GetAll(controller + '/Pendentes/Detalhes', dto)
    },
    GetPendentesGeral: function (dto) {
        return util.Axios.GetAll(controller + '/Pendentes/Geral', dto)
    }
})


