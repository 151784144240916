import util from "@bmp-vue/core/api/util";

const controller = "AntecipacaoRamificacao";
export default Object.assign({}, util.GetCrud(controller, null), {

  CriarAntecipacaoRamificacao: function (dto) {
    var credenciadora = {
      Codigo: dto.codigo,
      NomeCredenciadora: dto.nomeCredenciadora,
      Ativo: dto.ativo,
      ASLC032: dto.aslc032,
      ASLC024: dto.aslc024,
      ASLC022: dto.aslc022,
      DocumentoFederal: dto.documentoFederal
    };
    return util.Axios.Post(
      `${controller}/CriarAntecipacaoRamificacao`,
      credenciadora
    );
  },

  AtualizarAntecipacaoRamificacao: function (dto) {
    var credenciadora = {
      Codigo: dto.codigo,
      NomeCredenciadora: dto.nomeCredenciadora,
      Ativo: dto.ativo,
      ASLC032: dto.aslc032,
      ASLC024: dto.aslc024,
      ASLC022: dto.aslc022,
      DocumentoFederal: dto.documentoFederal
    };
    return util.Axios.Post(
      `${controller}/AtualizarAntecipacaoRamificacao`,
      credenciadora
    );
  }
});
