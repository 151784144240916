import util from '@bmp-vue/core/api/util'

const controller = 'ArquivoGerado'
export default Object.assign({}, util.GetCrud(controller, null), {
  GetArquivosXmlParaDownload(formData) {
    return util.Axios.GetBlob(controller + '/GetArquivosXmlParaDownload', formData)
  },
  DownloadXml(url) {
    return util.Axios.GetBlob(url)
  },
  Get(dto) {
    return util.Axios.GetAll(controller + '/Get', dto)
  },

  ValidarArquivosXmlParaDownload(dto) {
    return util.Axios.Post(controller + '/ValidarArquivosXmlParaDownload', dto)
  }
})
