import util from '@bmp-vue/core/api/util'

const controller = 'ControleArquivaria'
export default Object.assign({}, util.GetCrud(controller, ['persist']), {
    GetAllArquivaria: function (dto) {
        return util.Axios.GetAll(controller + '/Arquivaria', dto)
    },
    GetAllRecusas: function (dto) {
        return util.Axios.GetAll(controller + '/Recusas', dto)
    },
    GetAllR2C3: function (dto) {
        return util.Axios.GetAll(controller + '/R2C3', dto)
    },
    GetAllOperacoes: function (dto) {
        return util.Axios.GetAll(controller + '/Operacoes', dto)
    },
    AtivarAcoesArquivaria: function () {
        return util.Axios.Post(controller + '/Arquivaria/Ativar')
    },
    DesativarAcoesArquivaria: function () {
        return util.Axios.Post(controller + '/Arquivaria/Desativar')
    },
    AtivarAcoesRecusas: function () {
        return util.Axios.Post(controller + '/Recusas/Ativar')
    },
    DesativarAcoesRecusas: function () {
        return util.Axios.Post(controller + '/Recusas/Desativar')
    },
    AtivarAcoesR2C3: function () {
        return util.Axios.Post(controller + '/R2C3/Ativar')
    },
    DesativarAcoesR2C3: function () {
        return util.Axios.Post(controller + '/R2C3/Desativar')
    },
    AtivarAcoesOperacoes: function () {
        return util.Axios.Post(controller + '/Operacoes/Ativar')
    },
    DesativarAcoesOperacoes: function () {
        return util.Axios.Post(controller + '/Operacoes/Desativar')
    }
})
