import util from '@bmp-vue/core/api/util'

const controller = 'ReceberArquivoManual'
export default Object.assign({}, util.GetCrud(controller, ['getall', 'get', 'put', 'delete']), {
  Post: function (dto) {
    return util.Axios.PostForm(controller, dto)
  },
  Alerta: function (file) {
    console.log(file)
  }
})
