import util from '@bmp-vue/core/api/util'

const controller = 'Fundo'
export default Object.assign({}, util.GetCrud(controller, ['get', 'getall', 'persist']), {
    GetQuantidadeTransferencias(dto) {
        return util.Axios.GetAll(controller + '/Transferencias/Quantidade', dto)
    },
    GetAllTransferencias: function (dto) {
        return util.Axios.GetAll(controller + '/Transferencias', dto)
    },
    GetAllTransferenciasDetalhes: function (dto) {
        return util.Axios.GetAll(controller + '/Transferencias/Detalhes', dto)
    },
    GetAllTransferenciasGeral: function (dto) {
        return util.Axios.GetAll(controller + '/Transferencias/Geral', dto)
    }
})
