import util from '@bmp-vue/core/api/util'

const controller = 'EnvioSaldo'
export default Object.assign({}, util.GetCrud(controller, ['get', 'getall', 'persist', 'delete']), {
  ImportarLote: function (dto) {
    return util.Axios.PostForm(`${controller}/ImportarLote`, dto)
  },
  ExecutarTedsSincronia: function () {
    return util.Axios.PostForm(`${controller}/ExecutarTedsSincronia` )
  },

})
