import util from '@bmp-vue/core/api/util'

const controller = 'Autenticacao'
export default Object.assign({}, util.GetCrud(controller, ['none']), {
  Autenticar: function (login, senha, empresa, admin) {
    var dto = { Login: login, Senha: senha, Empresa: empresa, Admin: admin }
    return util.Axios.Post(controller + '/Autenticar', dto)
  },
  Logout: function () {
    return util.Axios.Delete(controller + '/Logout')
  },
  RefreshToken: function (token) {
    var dto = { RefreshToken: token }
    return util.Axios.Post(controller + '/RefreshToken', dto)
  },
  RevokeRefreshToken: function (token) {
    return util.Axios.Delete(controller + 'RevokeRefreshToken', token)
  }
})