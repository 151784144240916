import util from '@bmp-vue/core/api/util'

const controller = 'TransferenciaRamificacao'
export default Object.assign({}, util.GetCrud(controller, ['getall']), {
  ExecutarAntecipacaoRamificacao(dto) {
    return util.Axios.Post(controller + '/Antecipar', dto)
  },
  GetQuantidadeAntecipacoes(dto) {
    return util.Axios.GetAll(controller + '/Antecipar/Quantidade', dto)
  },
})
