import util from '@bmp-vue/core/api/util'

const controller = 'ArquivoRecebido'
export default Object.assign({}, util.GetCrud(controller, ['getall', 'get', 'put', 'delete']), {
  GetCreditos(dto) {
    return util.Axios.GetAll(`${controller}/Credito`, dto)
  },
  GetRelatorioEnvioRecepcaoPorDataOuNome(dto) {
    return util.Axios.GetAll(`${controller}/GetRelatorioEnvioRecepcaoPorDataOuNome`, dto)
  }
})
