import util from '@bmp-vue/core/api/util'

const controller = 'SaldoContaMae'
export default Object.assign({}, util.GetCrud(controller, ['getall', 'get', 'put', 'delete']), {
    CriarSaldoContaMae: function (dto) {
      return util.Axios.Post(
        `${controller}/CriarSaldoContaMae`,
        dto
      );
    },
    EditarSaldoContaMae: function (dto) {
      return util.Axios.Put(
        `${controller}`, dto.codigo,
        { valor: dto.valor }
      );
    }
})
