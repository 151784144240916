import util from '@bmp-vue/core/api/util'

const controller = 'ExclusaoLiquidacaoBmp'
export default Object.assign({}, util.GetCrud(controller, null), {
  ImportarLote: function (dto) {
    return util.Axios.PostForm(`${controller}/ImportarLote`, dto)
  },
  ExcluirLote: function (dto) {
    return util.Axios.PostForm(`${controller}/ExcluirLote`, dto)
  },
  Put: function(codigo, dto) {
    return util.Axios.Put(`${controller}`, codigo, dto);
  }
})
