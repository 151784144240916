import util from "@bmp-vue/core/api/util";

const controller = "CredenciadoraArquivariaDBS";
export default Object.assign({}, util.GetCrud(controller, null), {

  CriarCredenciadoraArquivariaDBS: function (dto) {
    var credenciadora = {
      Codigo: dto.codigo,
      NomeCredenciadora: dto.nomeCredenciadora,
      Ativo: dto.ativo,
      DocumentoFederal: dto.documentoFederal,
      Motivo: dto.motivo
    };
    return util.Axios.Post(
      `${controller}`,
      credenciadora
    );
  },

  AtualizarCredenciadoraArquivariaDBS: function (dto) {
    var credenciadora = {
      Codigo: dto.codigo,
      NomeCredenciadora: dto.nomeCredenciadora,
      Ativo: dto.ativo,
      DocumentoFederal: dto.documentoFederal,
      Motivo: dto.motivo
    };
    return util.Axios.Put(
      `${controller}`, credenciadora.Codigo,
      credenciadora
    );
  },
  ImportarLote: function (dto) {
    return util.Axios.PostForm(`${controller}/ImportarLote`, dto)
  },
  ExcluirLote: function (dto) {
    return util.Axios.PostForm(`${controller}/ExcluirLote`, dto)
  }
});
