import util from '@bmp-vue/core/api/util'

const controller = 'ErroTransFundo'
export default Object.assign({}, util.GetCrud(controller, ['getall', 'get', 'put', 'delete', 'persist']), {

    GetRelatorioErroTransferenciaFundoPorDataOuDocumentoFederal(dto) {
        return util.Axios.GetAll(`${controller}/GetRelatorioErroTransferenciaFundoPorDataOuDocumentoFederal`, dto)
    },
    FazerTransferenciaFundo(dto) {
        return util.Axios.Post(`${controller}/FazerTransferenciaFundo`, dto)
    },
    FazerTed(dto) {
        return util.Axios.Post(`${controller}/FazerTed`, dto)
    },
    Resolvido(dto) {
        return util.Axios.Post(`${controller}/Resolvido`, dto)
    }
})
