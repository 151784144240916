import util from "@bmp-vue/core/api/util";

const controller = "Pessoa";
export default Object.assign({}, util.GetCrud(controller, null), {
  CriarContaSafePorDocumentoFederal: function(dto) {
    const listaDocumentoFederal = {
      DocumentosFederais: [
        {
          Nome: dto.nome,
          Situacao: 1,
          TipoPessoa: "",
          DocumentoFederal: dto.documentoFederal,
          Email: dto.email,
          CadastroUsuarioSso: dto.cadastroUsuarioSso
        }
      ]
    };

    return util.Axios.Post(
      `${controller}/CriarContaSafePorDocumentoFederal`,
      listaDocumentoFederal
    );
  },

  AtualizarContaSafePorDocumentoFederal: function(dto) {
    var documentoFederal = {
      Nome: dto.nome,
      Situacao: 1,
      TipoPessoa: "",
      DocumentoFederal: dto.documentoFederal,
      Email: dto.email,
      CadastroUsuarioSso: dto.cadastroUsuarioSso
    };

    return util.Axios.Post(
      `${controller}/AtualizarContaSafePorDocumentoFederal`,
      documentoFederal
    );
  },

  AtualizarSituacaoEcSso: function (dto) {
    var pessoa = {
      Nome: dto.nome,
      Situacao: 1,
      TipoPessoa: "",
      DocumentoFederal: dto.documentoFederal,
      Email: dto.email,
      CadastroUsuarioSso: dto.cadastroUsuarioSso
    };

    return util.Axios.Post(`${controller}/AtualizaSituacaoEcSso`, pessoa);
  },
  
  GetPessoaPorDocumentoFederal: function(documentoFederal) {
    const url = `GetPessoaPorDocumentoFederal?documentoFederal=${documentoFederal}`;
    return util.Axios.GetAll(`${controller}/${url}`);
  },

  GetContaCorrentePorDocumentoFederal: function(documenoFederal) {
    const url = `GetContaCorrentePorDocumentoFederal?documentoFederal=${documenoFederal}`;
    return util.Axios.GetAll(`${controller}/${url}`);
  },

  ReenvioDeCredenciaisSsoPorEmail: function (codigoParceiro) {
    const url = `CadastrarUsuarioDoEcNoSso?codigo=${codigoParceiro}`;
    return util.Axios.Post(`${controller}/${url}`);
  },

  SalvarContaCorrente: function (documentoFederal, ativo) {
    var contaCorrente = {
      DocumentoFederal: documentoFederal,
      Ativo: ativo
    };

    return util.Axios.Post(`${controller}/SalvarContaCorrente`, contaCorrente);
  }
});
