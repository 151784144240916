import util from '@bmp-vue/core/api/util'

const controller = 'ArquivoRecebidoErro'
export default Object.assign({}, util.GetCrud(controller, ['getall']), {

    RegerarArquivoRecebido: function (dto) {
        return util.Axios.Post(controller + '/RegerarArquivoRecebido', dto)
    },

    RegerarArquivoRecebido: function (codigoArquivoRecebido) {        
        const url = `RegerarArquivoRecebido?codigoArquivoRecebido=${codigoArquivoRecebido}`
        return util.Axios.Post(`${controller}/${url}`)
    }
})
