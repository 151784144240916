import util from '@bmp-vue/core/api/util'

const controller = 'Dominio'
export default Object.assign({}, util.GetCrud(controller, ['get', 'getall', 'persist']), {
  GetByTipo: function (tipo, codigo) {
    const params = {
      parameters: [
        { name: 'tipo', value: tipo },
        { name: 'codigo', value: codigo }
      ]
    }
    return util.Axios.GetAll(controller, params)
  }
})
