import util from '@bmp-vue/core/api/util'

const controller = 'Dashboard'
export default Object.assign({}, util.GetCrud(controller, ['get', 'getall', 'persist']), {

  GetQuantidadeTeds(dto) {
    return util.Axios.GetAll(controller + '/GetQuantidadeTeds', dto)
  },
  GetQuantidadeRamificar(dto) {
    return util.Axios.GetAll(controller + '/GetQuantidadeRamificar', dto)
  },
  GetQuantidadeRamificarAtrasadas() {
    return util.Axios.GetAll(controller + '/GetQuantidadeRamificarAtrasadas')
  },
  GetSituacaoPVS(dto) {
    return util.Axios.GetAll(controller + '/GetSituacaoPVS', dto)
  },
  GetDocumentosFederaisTedsPendentes(dto) {
    return util.Axios.GetAll(controller + '/GetDocumentosFederaisTedsPendentes', dto)
  },
  GetDadosRamificarPendentes(dto) {
    return util.Axios.GetAll(controller + '/GetDadosRamificarPendentes', dto)
  },
  GetConfrontoDadosSilocEArquivaria(dto) {
    return util.Axios.GetAll(controller + '/GetConfrontoDadosSilocEArquivaria', dto)
  },
  GetDadosSiloc5(dto) {
    return util.Axios.GetAll(controller + '/GetDadosSiloc5', dto)
  },
  GetDetalhesDadosSiloc5(dto) {
    return util.Axios.GetAll(controller + '/GetDetalhesDadosSiloc5', dto)
  },
  GetDetalhesDadosSiloc5Arquivaria(dto) {
    return util.Axios.GetAll(controller + '/Siloc5Dados/Detalhes/Arquivaria', dto)
  },
  GetDadosLdl(dto) {
    return util.Axios.GetAll(controller + '/GetDadosLdl', dto)
  },
  GetValorLtr(dto) {
    return util.Axios.GetAll(controller + '/GetValorLtr', dto)
  },
  GetValorLdl(dto) {
    return util.Axios.GetAll(controller + '/GetValorLdl', dto)
  },
  GetSaldoContaSafe(dto) {
    return util.Axios.GetAll(controller + '/GetSaldoContaSafe', dto)
  },
  GetValorRamificacao(dto) {
    return util.Axios.GetAll(controller + '/ValorRamificacao', dto)
  },
  GetDetalhesConfrontoDadosArquivaria(dto) {
    return util.Axios.GetAll(controller + '/ConfrontoDados/Detalhes/Arquivaria', dto)
  },
  GetDetalhesConfrontoDadosSiloc(dto) {
    return util.Axios.GetAll(controller + '/GetDetalhesConfrontoDadosSiloc', dto)
  },
  GetExtratoContaSafe(dto) {
    return util.Axios.GetAll(controller + '/ExtratoContaSafe', dto)
  }
})
