import util from '@bmp-vue/core/api/util'

const controller = 'Operador'
export default Object.assign({}, util.GetCrud(controller, null), {
  SolicitarLinkSenha: function (dto) {
    return util.Axios.Post(controller + '/SolicitarLinkSenha', dto)
  },
  RecuperarSenha: function (dto) {
    return util.Axios.Post(controller + '/RecuperarSenha', dto)
  },
  ValidaTokenSenha: function (token) {
    const params = { token: token }
    return util.Axios.GetParams(controller + '/ValidaTokenSenha', params)
  },
  Combo: function () {
    return util.Axios.Get(controller + '/GetToSelect')
  }
})
