import util from '@bmp-vue/core/api/util'

const controller = 'ContaDestinoTed'
export default Object.assign({}, util.GetCrud(controller, ['persist']), {
  GetContaDestinoPorDocumentoFederal: function (documentoFederal) {
    const url = `documento-federal/${documentoFederal}`;
    return util.Axios.GetAll(`${controller}/${url}`);
  },
})

