import util from '@bmp-vue/core/api/util'

const controller = 'ArquivoRecebidoPontoVenda'
export default Object.assign({}, util.GetCrud(controller, ['getall', 'get', 'put', 'delete']), {
    GetSituacaoPVs: function (dto) {
        return util.Axios.GetAll(controller + '/GetSituacaoPVs', dto)
    },
    GetAllNuLiquids: function (dto) {
        return util.Axios.GetAll(controller + '/NuLiquids', dto)
    },
    GetAllRegistrosRamificados(dto){
        return util.Axios.GetAll(`${controller}/RegistrosRamificados`, dto)
    },
    GetAllRegistrosRamificadosDetalhes(dto){
        return util.Axios.GetAll(`${controller}/RegistrosRamificados/Detalhes`, dto)
    },
    GetAllRegistrosRamificadosGeral(dto){
        return util.Axios.GetAll(`${controller}/RegistrosRamificados/Geral`, dto)
    },
    GetAllRegistrosARamificar(dto){
        return util.Axios.GetAll(`${controller}/RegistrosARamificar`, dto)
    },
    GetAllRegistrosARamificarDetalhes(dto){
        return util.Axios.GetAll(`${controller}/RegistrosARamificar/Detalhes`, dto)
    },
    GetAllRegistrosARamificarGeral(dto){
        return util.Axios.GetAll(`${controller}/RegistrosARamificar/Geral`, dto)
    },
    GetAllRegistrosAtrasados(dto){
        return util.Axios.GetAll(`${controller}/RegistrosAtrasados`, dto)
    },
    GetAllRegistrosAtrasadosDetalhes(dto){
        return util.Axios.GetAll(`${controller}/RegistrosAtrasados/Detalhes`, dto)
    },
    GetAllRegistrosAtrasadosGeral(dto){
        return util.Axios.GetAll(`${controller}/RegistrosAtrasados/Geral`, dto)
    }
})

