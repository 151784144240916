import util from '@bmp-vue/core/api/util'

const controller = 'EmailResponsavel'
export default Object.assign({}, util.GetCrud(controller, null), {

  AtualizarEmailResponsavel: function (dto) {
    var emailResponsavel = {
      Codigo: dto.codigo,
      Nome: dto.nome,
      Setor: dto.setor,
      Email: dto.email,
    };

    return util.Axios.Post(
      `${controller}/AtualizarEmailResponsavel`,
      emailResponsavel
    );
  },
})
