import util from '@bmp-vue/core/api/util'

const controller = 'Devolucao';
export default Object.assign({}, util.GetCrud(controller, []), {
    GetAllConfronto: function (dto) {
        return util.Axios.GetAll(controller + '/Confronto', dto)
    },
    GetAllDebitoCredito: function (dto) {
        return util.Axios.GetAll(controller + '/DebitoCredito', dto)
    },
    GetAllAntecipacao: function (dto) {
        return util.Axios.GetAll(controller + '/Antecipacao', dto)
    },
    EnviarLDL0022: function (dto) {
        return util.Axios.Post(controller + '/LDL0022', dto)
    },
    EnviarLTR0004: function (dto) {
      return util.Axios.Post(controller + '/LTR0004', dto)
    },
});  
