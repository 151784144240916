import util from '@bmp-vue/core/api/util'

const controller = 'GeracaoArquivo'
export default Object.assign({}, util.GetCrud(controller, ['persist']), {
  Get(dto) {
    return util.Axios.GetAll(controller + '/Get', dto)
  },
  GetValoresGeracaoArquivo(dto) {
    return util.Axios.GetAll(controller + '/GetValoresGeracaoArquivo', dto)
  },
  GetContadoresSituacaoRegistros(dto) {
    return util.Axios.GetAll(controller + '/GetContadoresSituacaoRegistros', dto)
  },
  GetProcessosEmAndamento(dto) {
    return util.Axios.GetAll(controller + '/GetProcessosEmAndamento', dto)
  },
  DeleteAllProcessosEmAndamento(dto) {
    return util.Axios.Post(controller + '/LimparProcessosEmAndamento', dto)
  }
})
